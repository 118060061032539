<template>
  <div class="new-disease-container">
    <div class="disease-card" v-for="firstArray in data" :key="firstArray.type">
      <div class="title">{{firstArray.name}}</div>
      <div class="checkbox-panel">
        <template v-if="firstArray.type !== 5">
          <template v-for="(item, index) in firstArray.list">
            <Checkbox class="checkbox-content" :key="item.id + 'x'" v-model="item.value">{{item.name}}</Checkbox>
            <br v-if="((index + 1) % 4 === 0)" :key="item.id">
          </template>
        </template>
        <div class="sub-disease-wrapper" v-else>
          <template v-for="secondArray in firstArray.list">
            <div class="sub-disease" :key="secondArray.name">
              <span>{{secondArray.name}}</span>
              <Checkbox class="checkbox-content" v-for="secondItem in secondArray.list" :key="secondItem.id" v-model="secondItem.value">{{secondItem.name}}</Checkbox>
            </div>
          </template>
        </div>
      </div>
      <hr class="divider" />
    </div>
    <div class="remark-panel" v-if="data.length != 0">
      <div class="title">6.备注</div>
      <Input v-model="other" type="textarea" rows="3" placeholder="请输入..."></Input>
    </div>
    <div class="symbol-box tac">
      <Button type="primary" size="large" @click="handleBack">返回</Button>
      <Button type="success" size="large" @click="handleSave">保存</Button>
    </div>
  </div>
</template>
<script type="text/ecmascript-6">
import memberService from '@/services/memberService';

export default {
	data() {
		return {
			data: [],
			other: '',
		};
	},
	created() {
		memberService
			.newDiseaseData({
				method: 0,
				member_id: this.$route.params.member_id,
			})
			.then((data) => {
				this.data = data.value;
				this.other = data.other;
			});
	},
	methods: {
		handleBack() {
			this.$router.back();
		},
		handleSave() {
			const params = {
				method: 1,
				member_id: this.$route.params.member_id,
				value: JSON.stringify(this.data),
				other: this.other,
			};
			memberService.newDiseaseData(params).then(() => {
				this.$Message.success('保存成功');
				this.handleBack();
			});
		},
	},
};
</script>
<style lang="less" scoped>
.new-disease-container {
  background-color: #fff;
  padding: 20px;
  .disease-card {
    margin: 0 auto 20px;
    width: 80%;
    display: flex;
    flex-direction: column;
    .title {
      color: blue;
      font-weight: 700;
      font-size: 16px;
    }
    .divider {
      width: 100%;
      height: 2px;
      background-color: #eee;
      margin-top: 20px;
    }
    .checkbox-panel {
      margin: auto;
    }
    .checkbox-content {
      font-size: 16px;
      width: 150px;
      margin-top: 10px;
    }
    .sub-disease-wrapper {
      display: flex;
      margin: auto;
      .sub-disease {
        span {
          color: blue;
          font-size: 16px;
          margin: 10px 0 0 20px;
          display: inline-block;
        }
        width: 160px;
      }
    }
  }
  .remark-panel {
    margin: 0 auto 20px;
    width: 80%;
    .title {
      color: blue;
      font-weight: 700;
      font-size: 16px;
      margin-bottom: 10px;
    }
  }
  .go-to-behavior {
    text-align: right;
  }
}
</style>
